@import url(https://fonts.googleapis.com/css2?family=Inter:wght@400;500;700&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.tab-menu > button {
  cursor: pointer;
  padding: 8px 16px;
  border: 0;
  border-bottom: 2px solid transparent;
  background: none;
}

.tab-menu > button.focus {
  border-bottom: 2px solid #007bef;
}

.tab-menu > button:hover {
  border-bottom: 2px solid #007bef;
}

.tab-content {
  display: none;
}

.tab-content.selected {
  display: block;
}
html{
	font-size: 112.5%;
}

body{
	font-family: 'Inter', sans-serif;
    font-weight: 400;
}

table{
	margin-top: 2rem;
	width: 100%;
}

table td, table th{
	padding: .75rem;
	border-top: 1px solid #dee2e6;
	text-align: left;
}

table thead tr{
	background: #B3BCD8;
}

table thead th{
	border-top: 1px solid #dee2e6;
	padding: .5rem .75rem;
	color: #0C1D57;
}

.container{
    width: 100%;
    margin: 0 auto;
    padding: 0 2rem;
    position: relative;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    box-sizing: border-box;
	max-width: 1270px;
}

header.site-header{
	padding-top: 1rem;
	margin-bottom: 2rem;
}

header.site-header .container{
	-webkit-justify-content: space-between;
	        justify-content: space-between;
}

nav.main-nav, .site-navigation{
	display: -webkit-flex;
	display: flex;
	-webkit-align-items: center;
	        align-items: center;
}

nav.main-nav {
	display: -webkit-flex;
	display: flex;
}

nav.main-nav a{
	list-style: none;
	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: center;
	        justify-content: center;
	padding: 0 .35rem;
	text-decoration: none;
	font-size: .85rem;
	color: #0C1D57;
}

nav.main-nav a:hover{
	text-decoration: underline;

}

.welcome-notice .container{
	-webkit-justify-content: flex-end;
	        justify-content: flex-end;
}

.welcome-notice span{
	display: block;
	margin-bottom: .5rem;
	float: right;
	border: 1px solid #49A7EB;
	background: #d6dbeb;
	border-top: 0;
	padding: .25rem .5rem;
	border-bottom-right-radius: 5px;
	border-bottom-left-radius: 5px;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.error{
  color:darkred;
}
.d-block {
  position: fixed;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.6);
  width: 100%;
  height: 100vh;
  text-align: center;
}

.modal-container {
  position: absolute;
  width: 60vw;
  background: #ffffff;
  left: 20%;
  top: 5%;
  padding: 20px;
  overflow: scroll;
  max-height: 80vh;
}

input{
	font-size: 1rem;
	padding: .35rem .85rem .35rem .5rem;
	border: 2px solid #919dc8;
	border-radius: .25rem;
}

button{
	-webkit-appearance: none;
	        appearance: none;
    border: none;
	font-size: 1rem;
	font-family: 'Inter', sans-serif;
    font-weight: 400;
	background: #0C1D57;
	color: #FFF;
	padding: .5rem 1rem;
    border-radius: .25rem;
    text-decoration: none;
    transition: ease-in-out all .15s;
}

button:hover{
	background: #49A7EB;
}

select{
	-webkit-appearance: none;
	        appearance: none;
	padding: 0.5rem 3.25rem 0.375rem 0.5rem;
	background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='%23FFF' stroke='%2349A7EB' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
	background-repeat: no-repeat;
	background-position: 98.5% 50%;
	background-size: .85rem;
	border-radius: .25rem;
	border: 2px solid #919dc8;
	color: #0C1D57;
	font-size: 1rem;
	margin-top: .5rem;
}

label{
	font-size: .85rem;
	margin: .5rem;
	font-weight: 600;
	color: #0C1D57;
	-webkit-align-self: flex-start;
	        align-self: flex-start;
}

fieldset{
	border: 0;
}

.login__container{
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: column;
	        flex-direction: column;
	-webkit-align-items: center;
	        align-items: center;
	-webkit-justify-content: center;
	        justify-content: center;
	width: 100%;
	max-width: 350px;
	background: #b3bcd86e;
	border: 2px solid #919dc8;
	border-radius: .25rem;
	padding: 1rem 1.5rem 1.5rem;
	margin: 5rem auto 2rem;
}

.login__container .site-logo{
	margin-bottom: 1rem;
}

.login__container label{
	margin-left: 0;
}

.login__div{
	width: 100%;
}

.login__div__header{
	text-align: center;
	font-weight: 600;
	margin: 1rem 0d;
}

.login__div__username, .login__div__password{
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: column;
	        flex-direction: column;
}

.login__actions{
	display: -webkit-flex;
	display: flex;
	width: 100%;
	margin-top: 1rem;
	-webkit-align-items: center;
	        align-items: center;
	-webkit-flex-direction: column;
	        flex-direction: column;
}

.login__remember{
	margin-bottom: .5rem;
}

.login__remember label{
	margin-left: .25rem;
}

.login__forgot{
	margin-top: .5rem;
}

.vinSearch{

}

.vinSearch label, .vinSearch input{
	margin-right: .5rem;
}

.vin-validator{

}

.vin-validator input{
	margin-right: .5rem;
}

.booking--available-ports{
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: column;
	        flex-direction: column;
	-webkit-align-items: center;
	        align-items: center;
}

.booking--available-ports > div{
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: column;
	        flex-direction: column;
	-webkit-align-items: center;
	        align-items: center;
	width: 100%;
	max-width: 600px;
	margin-bottom: .5rem;
}

.booking--available-ports select{
	width: 100%;
}

.party-selection{
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: row;
	        flex-direction: row;
	-webkit-align-items: center;
	        align-items: center;
	-webkit-justify-content: center;
	        justify-content: center;
	margin-bottom: 2rem;
}

.party-selection > div{
	border: 2px solid #919dc8;
	margin-right: .5rem;
	border-radius: .25rem;
	padding: .5rem;
}

.party-selection label{
	background: #919dc8;
	display: block;
	width: 100%;
	margin: 0;
	padding: .5rem;
	box-sizing: border-box;
}

.mapped-table .container{
	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: center;
	        justify-content: center;
	-webkit-flex-direction: column;
	        flex-direction: column;
}

.mapped-table__search{
	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: flex-end;
	        justify-content: flex-end;
}

.select-container{
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: column;
	        flex-direction: column;
	margin-bottom: 1rem;
}

.select-container label{
	margin-left: 0;
	margin-bottom: 0;
}

.new-booking__container{
	-webkit-flex-direction: column;
	        flex-direction: column;
	-webkit-justify-content: center;
	        justify-content: center;
}

.bookingInformation input{
	border: 0;
	padding: 0;
	min-width: 20rem;
	text-align: right;
}

.bookingInformation > div{
	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: space-between;
	        justify-content: space-between;
}

.card{
	border: 2px solid #919dc8;
	border-radius: .25rem;
	margin-bottom: 2rem;
}

.card fieldset{
	padding: 0;
	margin: 0;
}

.card fieldset label{
	margin-left: 0;
}

.card-header{
	background: #919dc8;
	display: block;
	width: 100%;
	margin: 0;
	padding: .5rem;
	box-sizing: border-box;
}

.card-header h3{
	margin: 0;
	text-align: left;
}

.card-body{
	padding: 1rem .5rem;
}

.card-body p{
	text-align: left;
}

.new-booking__vehicles .modal .modal-container .modal-body{
	display: -webkit-flex;
	display: flex;
}

.new-booking__vehicles .modal .modal-container .modal-body .card{
	margin: 0 .5rem 2rem;
}

.new-booking__vehicles .modal .modal-container .modal-body fieldset{
	width: 50%;
}

.new-booking__vehicles .vehicleInfo{
	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: space-between;
	        justify-content: space-between;
	-webkit-align-items: center;
	        align-items: center;
	margin-bottom: .5rem;
}

.new-booking__vehicles .vin-validator input, .new-booking__vehicles .vin-validator label, .new-booking__vehicles .vin-validator select{
	margin: 0;
}

.new-booking__vehicles .vin-validator  input.button{
	text-align: center;
	padding: .45rem;
	margin: 0 .5rem;
}
